import { Injectable, OnDestroy } from '@angular/core';
import { updateFeatureToggles } from '@app/reducers/feature/feature.actions';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, shareReplay, startWith } from 'rxjs/operators';
import { EVENTS, UnleashClient } from 'unleash-proxy-client';

import { environment } from '../../environments/environment';
import { SubscriptionModel } from '../+authenticated/+reports/shared/subscriptions/subscription.model';
import { getAccount, getAccountSubscription } from '../reducers/account/account.service';
import { getPermissionState } from '../reducers/auth/permission.helper';
import { AppState } from '../reducers/index';
import { EmployeeModel } from '../reducers/orm/employee/employee.model';
import { getAuthenticatedUser } from '../reducers/orm/employee/employee.service';

interface FeatureFunc {
  (store?: Store<AppState>): boolean;
}

export interface FeatureOverview {
  [name: string]: boolean | FeatureFunc;
}

@Injectable()
export class FeatureService implements OnDestroy {
  /** @deprecated Feature service is now initialized on APP_INITIALIZER so the feature client is always ready */
  public featureClientReady = new BehaviorSubject<boolean>(false);
  private currentEmployee: EmployeeModel;
  private currentSubscription: SubscriptionModel;
  private isAccountManager: boolean;
  private resellerId: string;

  private unleash: UnleashClient = new UnleashClient({
    url: environment.unleashUrl + '/api/frontend',
    clientKey: environment.unleashClientKey,
    appName: 'web',
    environment: environment.env,
  });

  public readonly update$ = fromEvent<void>(this.unleash, EVENTS.UPDATE).pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  public readonly error$ = fromEvent<void>(this.unleash, EVENTS.ERROR).pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private features: FeatureOverview = {
    skills: true,
    vectron: true,
    mrwinston: true,
  };

  private dataSubs = new Subscription();

  public constructor(private store: Store<AppState>) {
    this.dataSubs.add(this.getEmployeeData());
    this.subscribeToUpdateEvent();
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }

  public async load() {
    this.updateContext();
    await this.unleash.start();
    this.featureClientReady.next(true);
  }

  // subscribe to the update event and update the store with the new toggles this can be used for having selectors that rely on feature flags
  private subscribeToUpdateEvent(): void {
    this.dataSubs.add(
      this.update$
        .pipe(
          map(() =>
            this.unleash
              .getAllToggles()
              .map((toggle) => ({ key: toggle.name, enabled: toggle.enabled }))
              .sort((a, b) => a.key.localeCompare(b.key)),
          ),
          startWith(null),
          pairwise(),
          distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
          // due to lazy select reselecting the same state we need to filter out the same state
        )
        .subscribe(([, toggles]) => {
          this.store.dispatch(updateFeatureToggles({ toggles }));
        }),
    );
  }

  public isFeatureActivated(name: string): boolean {
    if (typeof this.features[name] === 'function') {
      const func = this.features[name] as FeatureFunc;
      return func(this.store);
    } else {
      return (this.features[name] as boolean) || this.unleash?.isEnabled(name);
    }
  }

  public isEnabled(featureFlag: string): boolean {
    return this.unleash?.isEnabled(featureFlag);
  }

  public isDisabled(featureFlag: string): boolean {
    return !this.unleash?.isEnabled(featureFlag);
  }

  public isEnabled$(featureFlag: string): Observable<boolean> {
    return this.update$.pipe(
      startWith(null),
      map(() => this.isEnabled(featureFlag)),
      distinctUntilChanged(),
    );
  }

  public isDisabled$(featureFlag: string): Observable<boolean> {
    return this.isEnabled$(featureFlag).pipe(map((enabled) => !enabled));
  }

  public getAllFeatureVariables(featureKey: string, numberToFloat?: boolean) {
    const variants = this.unleash.getVariant(featureKey);
    if (!variants.enabled || !variants.payload) {
      return {};
    }

    const payload = variants.payload;
    switch (payload.type) {
      case 'json':
        return JSON.parse(payload.value);
      case 'string':
        return payload.value;
      case 'number':
        return numberToFloat ? parseFloat(payload.value) : parseInt(payload.value);
      default:
        return {};
    }
  }

  private getEmployeeData() {
    return combineLatest([
      this.store.select(getAccount),
      this.store.select(getAuthenticatedUser),
      this.store.select(getPermissionState),
      this.store.select(getAccountSubscription),
    ])
      .pipe(
        filter(
          ([account, employee, permissionState, accountSubscription]) =>
            !!account && !!employee && !!permissionState && !!accountSubscription,
        ),
      )
      .subscribe(([account, employee, permissionState, accountSubscription]) => {
        this.currentEmployee = employee;
        this.currentSubscription = accountSubscription;
        this.isAccountManager = permissionState.isAccountManager;
        this.resellerId = account?.AccountType?.reseller_id;
        this.updateContext();
      });
  }

  private updateContext() {
    this.unleash.updateContext({
      userId: this.currentEmployee?.id,
      properties: {
        accountId: this.currentEmployee?.account_id,
        appVersion: environment.version,
        instance: environment.instance,
        isAccountManager: this.isAccountManager ? 'true' : 'false',
        plan: this.currentSubscription?.plan?.type ?? '',
        resellerId: this.resellerId,
      },
    });
  }
}
